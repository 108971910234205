import './style.css';

export default function SquareViewport({ viewportWidth, viewportHeight }) {
  return (
    <div className='invalid-viewport-container'>
      <p>O overlay precisa ser usado como fonte de navegador no OBS ou Streamlabs!</p>
      <p>Tanto a largura como a altura precisam ser iguais.</p>
      <p>Quando os tamanhos estiverem corretos a fonte ficará transparente.</p>
      <p>Exemplo: 300x300, 400x400, 500x500</p>
      <p>
        Propriedades atuais da sua fonte: {viewportWidth}x{viewportHeight}
      </p>
    </div>
  );
}
