import './style.css';

function NotFound() {
  return (
    <div className='notfound-container'>
      <h1>Link incorreto</h1>
    </div>
  );
}

export default NotFound;
