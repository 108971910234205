import { useContext, useEffect, useRef } from 'react';
import { Avatar } from '@material-ui/core';
import { gsap } from 'gsap';

import './style.css';
import Logo from '../../assets/logo.png';
import { TogglePages } from '../../contexts/togglePages';

export default function Raid({ data }) {
  const { dispatch } = useContext(TogglePages);
  const textLabel = useRef();

  useEffect(() => {
    const tl = gsap.timeline();

    tl.from('.header', { scale: 0, duration: 1 })
      .fromTo('.header', { y: 0 }, { y: 10, repeat: '-1', yoyoEase: true, duration: 3 }, '-=1')
      .fromTo('.raid-container-title', { y: 20 }, { opacity: 1, y: '-10' }, '-=2.5')
      .from(textLabel.current, { y: 20 }, '-=0.5')
      .fromTo(textLabel.current, { rotate: '-32deg' }, { rotate: '-21deg', repeat: '-1', yoyoEase: true, duration: 3 })
      .to('.raid-container', { scale: 0, duration: 1, onComplete: () => dispatch({}) }, '+=8');
  }, [dispatch]);

  return (
    <div className='raid-wrapper'>
      <div className='raid-container'>
        <div className='header'>
          <Avatar className='avatar' alt='Imagem do streamer' src={data.avatar} />
        </div>

        <div className='raid-container-title'>
          <div className='label' ref={textLabel}>
            <span>RAID</span>
          </div>

          <span>{data.twitchUsername} fez uma invasão com {data.viewers} pessoas!!</span>

          <img src={Logo} alt='Logo da MOS.gg' />
        </div>
      </div>
    </div>
  );
}
