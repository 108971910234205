import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from '../pages/Index';
import NotFound from '../pages/NotFound';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/streamer/:id' top='true' component={Index} />
      <Route path='*' component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
