import { useCallback, useContext, useEffect, useRef } from 'react';
import { Avatar } from '@material-ui/core';
import { gsap } from 'gsap';

import './style.css';
import Logo from '../../assets/logo.png';
import { TogglePages } from '../../contexts/togglePages';
import { mosShare } from '../../util/botCommands';
import { avatarBorderColor } from '../../contants/avatarBorderColor';

export default function Share({ data }) {
  const { dispatch } = useContext(TogglePages);
  const titleRef = useRef();
  const gameRef = useRef();

  const animation = useCallback(() => {
    const tl = gsap.timeline();

    tl.set('.avatar', { display: 'flex' })
      .from('.header', { scale: 0, duration: 1 })
      .fromTo('.share-container-title', { y: 20 }, { opacity: 1, y: '-10' }, '-=0.5')
      .fromTo('.trophy', { y: 0 }, { y: 10, repeat: '-1', yoyoEase: 'none', duration: 2.2, ease: 'power1.out' }, '-=1');

    if (data && data.game) {
      tl.to(titleRef.current, { opacity: 0, display: 'none' }, '+=5').fromTo(
        gameRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1, display: 'flex' }
      );
    }

    tl.to(
      '.share-container',
      { scale: 0, duration: 1, onComplete: () => dispatch({ type: mosShare, toggle: false }) },
      '+=5'
    );
  }, [data, dispatch]);

  const setBorderColorAvatar = useCallback(() => {
    if (data && data.level) {
      const borderColor = avatarBorderColor(data.level.toLowerCase());

      document.body.style.setProperty('--border-color-avatar', borderColor);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.level) {
      const trophyImg = new Image();

      trophyImg.src = `https://api.mos.gg/v1/badges/${data.level.toLowerCase()}.png`;

      trophyImg.onload = () => {
        animation();
        setBorderColorAvatar();
      };
    } else {
      animation();
      setBorderColorAvatar();
    }
  }, [animation, data, data.level, setBorderColorAvatar]);

  return (
    <div className='share-wrapper'>
      <div className='share-container'>
        <div className='header'>
          <Avatar className='avatar' alt='Imagem do streamer' src={data.avatar} style={{ display: 'none' }} />

          {data && data.level && (
            <img className='trophy' src={`https://api.mos.gg/v1/badges/${data.level.toLowerCase()}.png`} alt='Troféu' />
          )}
        </div>

        <div className='share-container-title'>
          <span ref={titleRef}>{data.twitchUsername}</span>
          <span ref={gameRef}>{data.game}</span>

          <img src={Logo} alt='Logo da MOS.gg' />
        </div>
      </div>
    </div>
  );
}
