export function avatarBorderColor(level) {
  switch (level) {
    case 'madeira':
      return '#BB6356';

    case 'bronze':
      return '#C9473F';

    case 'prata':
      return '#8663AF';

    case 'ouro':
      return '#EC8839';

    case 'diamante':
      return '#314DF4';

    case 'mestre':
      return '#42293F';

    default:
      return 'transparent';
  }
}
