import { useEffect, useReducer, useState, useLayoutEffect } from 'react';
import { io } from 'socket.io-client';
import { TogglePages } from '../../contexts/togglePages';
import { INITIAL_STATE, showPages } from '../../store/reducer/showPages';
import { mos, mosShare } from '../../util/botCommands';

import About from '../About';
import Raid from '../Raid';
import Share from '../Share';
import SquareViewport from '../SquareViewport';

function Index({ match }) {
  const [state, dispatch] = useReducer(showPages, INITIAL_STATE);
  const [sharePageData, setSharePageData] = useState({});
  const [raidPageData, setRaidPageData] = useState({});
  const [squareViewport, setSquareViewport] = useState({
    valid: false,
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight
  });

  function checkViewportCorrect() {
    if (window.innerWidth === window.innerHeight) {
      setSquareViewport({
        valid: true,
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight
      });
    } else {
      setSquareViewport({
        valid: false,
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight
      });
    }
  }

  window.onresize = () => checkViewportCorrect();

  useLayoutEffect(() => checkViewportCorrect(), []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER_URL, { transports: ['websocket', 'polling'] });

    socket.emit('new:client', { path: match.params.id });

    socket.on('new:command', data => {
      switch (data.event) {
        case mos:
          dispatch({ type: mos, toggle: true });
          break;

        case mosShare:
          setSharePageData(data.user_data);
          dispatch({ type: mosShare, toggle: true });
          break;

        case 'raid':
          setRaidPageData(data.user_data);
          dispatch({ type: 'raid', toggle: true });
          break;

        default:
          break;
      }
    });

    socket.on('disconnect', reason => {
      if (reason === 'io server disconnect') {
        socket.connect();
      }
    });
  }, [match.params.id]);

  if (squareViewport.valid) {
    return (
      <TogglePages.Provider value={{ state, dispatch }}>
        {state && state.about && <About />}
        {state && state.share && <Share data={sharePageData} />}
        {state && state.raid && <Raid data={raidPageData} />}
      </TogglePages.Provider>
    );
  } else {
    return (
      <SquareViewport viewportWidth={squareViewport.viewportWidth} viewportHeight={squareViewport.viewportHeight} />
    );
  }
}

export default Index;
