import { mos, mosShare } from '../../util/botCommands';

export const INITIAL_STATE = { about: false, raid: false, share: false };

export function showPages(state = INITIAL_STATE, action) {
  switch (action.type) {
    case mos:
      return { ...state, about: action.toggle };

    case mosShare:
      return { ...state, share: action.toggle };

    case 'raid':
      return { ...state, raid: action.toggle };

    default:
      break;
  }
}
