import { gsap } from 'gsap';
import { useContext, useEffect } from 'react';
import { TogglePages } from '../../contexts/togglePages';
import { mos } from '../../util/botCommands';

import logo from '../../assets/spinner.png';
import './style.css';

export default function About() {
  const { dispatch } = useContext(TogglePages);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.from('.logo', {
      rotate: '-360',
      scale: 0,
      y: 150,
      duration: 1.4,
      ease: 'back.out(1.4)',
    })
      .to('.about-container-title', { opacity: 1, y: '-30', duration: 1 }, '-=0.3')
      .to('.about-container', { yPercent: '-4', repeat: '-1', duration: 1.5, yoyoEase: true }, '-=0.5')
      .to('.about-container', { scale: 0, onComplete: () => dispatch({ type: mos, toggle: false }) }, '+=8');
  }, []);

  return (
    <div className='about-container'>
      <img className='logo' src={logo} alt='Logo MOS.gg' />

      <div className='about-container-title'>
        <span>MOS.gg</span>
      </div>
    </div>
  );
}
